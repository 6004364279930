// @ts-nocheck
import React from "react";
import Search from "../../../svg/search.svg";
import "./searchIcon.scss";

type Props = {
  className?: string;
};
const SearchIcon = ({ className = "" }: Props) => (
  <Search className={`c-search-icon ${className}`} />
);

export default SearchIcon;
