// @ts-nocheck
// react-select should be updated and this pattern removed
/* eslint-disable react/no-unstable-nested-components */
// eslint-disable-next-line
import React, { useMemo, useRef, useState } from "react";
import Select, { components } from "react-select";
import bem from "@justpark/helpers/src/bem/bem";
import type { Node, Ref } from "react";
import type {
  ActionMeta,
  CommonProps,
  InputActionMeta,
  MenuPlacement,
  OptionProps as LibOptionProps,
  ValueType
} from "react-select";
import type {
  Option,
  OptionFormatFactory,
  OptionsArray,
  OptionGroupFormatFn
} from "./option";
import {
  addAllOptionToOptions,
  formatGroupLabel,
  makeFormatOptionLabelFn,
  moveSelectedOptionsToTop,
  useScrollOnMultiOptionSelect,
  isOptionDisabled
} from "./option";
import type { FilterFactory } from "./filter";
import makeDefaultFilter from "./filter";
import ChevronIcon from "../Icons/ChevronIcon";
import ClearIcon from "../Icons/ClearIcon";
import CrossIcon from "../Icons/CrossIcon";
import WarningIcon from "../Icons/WarningIcon";
import Error from "../Error";
import escapeRegExp from "../../helpers/escape-regexp";
import Checkbox from "../Checkbox";
import SearchIcon from "../Icons/SearchIcon";
import Typography from "../Typography";
import "./fancyDropdown.scss";

type SearchValueRegExpFactory = (searchValue?: string) => RegExp | null;
export type Props = {
  /**
   * The option to show for "Select All".
   */
  allOption?: Option | null;
  /**
   * The Component must have a label or be labelled with aria-labelledby or be
   * hidden from ATs.
   */
  ariaLabel?: string | void;
  /**
   * The Component must have a label or be labelled with aria-labelledby or be
   * hidden from ATs. This is the ID of the element describing the dropdown.
   */
  ariaLabelledBy?: string | void;
  className?: string;
  /**
   * Add data-cy selectors for Cypress testing
   */
  cypress?: string | null;
  /**
   * Can be cleared with the X icon. Setting this prop to true will show the icon.
   */
  clearable?: boolean;
  disabled?: boolean;
  error?: string;
  helpText?: string | Node;
  hideDropDownIndicator?: boolean;
  /**
   * If set, the input element will get this ID. This is useful if you want to
   * associate a label with the input.
   */
  id?: string | void;
  loading?: boolean;
  /**
   * Where to place the menu. "auto" will attempt to display below the input
   * unless there isn't enough room.
   */
  menuPlacement?: MenuPlacement;
  menuPortalTarget?: any;
  multi?: boolean;
  /**
   * The message to show when there are no options or no matching options
   * @param searchValue - The search text value
   */
  noOptionsMessage?: (searchValue?: string) => Node | null;
  optionFormatFactory?: OptionFormatFactory;
  options: OptionsArray;
  placeholder?: Node | string;
  /**
   * Can the list be searched through?
   */
  searchable?: boolean;
  onOptionDeselect?: (item: Option) => any;
  onOptionSelect: (item: Option | null) => any;
  selectedOption: Option | Option[] | null;
  showSelectedAtTopOfMenu?: boolean;
  warning?: boolean;
  label?: string | null;
  labelColor?: string;
  hideErrorCross?: boolean;
  hero?: boolean;
  menuPosition?: "absolute" | "fixed";
};
export type UIProps = Props & {
  filterFactory: FilterFactory;
  groupFormatFn: OptionGroupFormatFn;
  onBlur: () => void;
  onSearchValueChange: (newValue: string) => any;
  searchValue: string;
  searchValueRegExpFactory: SearchValueRegExpFactory;
  labelColor: string;
};
type ClearIndicatorProps = CommonProps & {
  innerProps: {
    ref?: Ref<any>;
  };
};
type IndicatorsContainerLibProps = CommonProps & {
  children: Node;
  isRtl: boolean; // we don't care about this right now
};
type IndicatorsContainerProps = IndicatorsContainerLibProps & {
  hasError: boolean;
  hasWarning: boolean;
  hideErrorCross: boolean;
};
type OptionProps = CommonProps &
  LibOptionProps & {
    children: any;
    innerProps: Record<string, any>;
    type: string;
  };
const baseClass = "c-fancy-dropdown";
const selectPrefix = `${baseClass}-select`;
const ClearIndicator = (props: ClearIndicatorProps) => {
  const {
    innerProps: { ref, ...otherInnerProps }
  } = props;
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div
      ref={ref}
      // style={getStyles("clearIndicator", props)}
      {...otherInnerProps}
    >
      <ClearIcon role="presentation" />
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};
const IndicatorsContainer = (props: IndicatorsContainerProps) => {
  const { children, hasError, hasWarning, hideErrorCross } = props;
  return (
    /* eslint-disable react/jsx-props-no-spreading */ // $FlowFixMe
    <components.IndicatorsContainer {...props}>
      {hasError && !hideErrorCross && (
        <CrossIcon
          className={bem(baseClass, "icon", {
            error: true
          })}
          modifier="error"
          role="presentation"
        />
      )}
      {hasWarning && !hasError && (
        <WarningIcon
          className={bem(baseClass, "icon", {
            warning: true
          })}
          role="presentation"
        />
      )}
      {children}
    </components.IndicatorsContainer>
    /* eslint-enable react/jsx-props-no-spreading */
  );
};

export const FancyDropdownUI = ({
  allOption = null,
  ariaLabel = undefined,
  ariaLabelledBy = undefined,
  label,
  labelColor,
  className = "",
  clearable = false,
  cypress = null,
  disabled = false,
  error = "",
  filterFactory = makeDefaultFilter,
  groupFormatFn = formatGroupLabel,
  helpText = "",
  hideDropDownIndicator = false,
  id = undefined,
  loading = false,
  menuPlacement = "auto",
  menuPortalTarget = null,
  multi = false,
  noOptionsMessage = () => (
    <Typography color="light" size="primary">
      No results
    </Typography>
  ),
  onBlur = () => {},
  onOptionDeselect = () => {},
  onOptionSelect,
  onSearchValueChange = () => {},
  optionFormatFactory,
  options,
  placeholder,
  searchable = false,
  searchValue = "",
  searchValueRegExpFactory = (val) =>
    val ? new RegExp(escapeRegExp(val), "gi") : null,
  selectedOption,
  showSelectedAtTopOfMenu = false,
  warning = false,
  hideErrorCross = false,
  hero = false,
  menuPosition = "absolute"
}: UIProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const selectRef = useRef();
  useScrollOnMultiOptionSelect(
    multi,
    menuOpen,
    showSelectedAtTopOfMenu,
    selectRef,
    selectedOption
  );
  const fullOptionList = showSelectedAtTopOfMenu
    ? moveSelectedOptionsToTop(
        addAllOptionToOptions(options, allOption),
        selectedOption,
        allOption
      )
    : addAllOptionToOptions(options, allOption);
  const searchValueRegExp = searchValueRegExpFactory(searchValue);
  const filter = filterFactory(allOption);
  const formatOptionLabel = optionFormatFactory(
    searchValueRegExp,
    hero ? label : undefined,
    labelColor
  );
  const onChange = (value: ValueType, { action }: ActionMeta) => {
    if (action === "select-option") {
      const selected: Option | null = (() => {
        if (Array.isArray(value)) {
          return value[value.length - 1];
        }
        return value || null;
      })();
      onOptionSelect(selected);
      if (!multi) {
        onSearchValueChange("");
      }
    } else if (action === "remove-value" || action === "deselect-option") {
      const removedOption = selectedOption.find(
        (option) => value.indexOf(option) === -1
      );
      onOptionDeselect(removedOption);
    } else if (action === "clear") {
      onOptionSelect(null);
      onSearchValueChange("");
    }
  };
  const onInputChange = (newValue: string, { action }: InputActionMeta) => {
    const maxLength = 80;
    if (action === "input-change") {
      onSearchValueChange(
        newValue.length <= maxLength ? newValue : newValue.substr(0, maxLength)
      );
    }
  };
  const component = (
    <div
      className={`${bem(baseClass, null, {
        error: !!error,
        "menu-open": menuOpen,
        "is-multi": multi,
        "is-searchable": searchable,
        warning,
        hero
      })} ${className}`}
      data-cy={cypress === null ? null : cypress}
    >
      <Select
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        className={selectPrefix}
        classNamePrefix={selectPrefix}
        closeMenuOnSelect={!multi}
        components={{
          ClearIndicator,
          DropdownIndicator: (props: CommonProps) =>
            hideDropDownIndicator ? null : (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <components.DropdownIndicator {...props}>
                <ChevronIcon direction="down" />
              </components.DropdownIndicator>
            ),
          IndicatorsContainer: (props: IndicatorsContainerLibProps) => {
            const { children } = props;
            return (
              // $FlowFixMe - complaining about missing props which are in the spread
              <IndicatorsContainer
                hasError={!!error}
                hasWarning={warning}
                hideErrorCross={hideErrorCross}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              >
                {children}
              </IndicatorsContainer>
            );
          },
          MultiValueContainer: (
            props: CommonProps & {
              data: Option;
            }
          ) => {
            const {
              data: { value }
            } = props;
            if (value === selectedOption[0].value) {
              const othersCount = selectedOption.length - 1;
              const othersLabel = ` & ${othersCount} ${
                othersCount === 1 ? "other" : "others"
              }`;
              return (
                <div
                  className={bem(baseClass, "option", {
                    "selected-item": true
                  })}
                >
                  <Typography>{selectedOption[0].label}</Typography>
                  {othersCount > 0 && (
                    <Typography>
                      <pre>{othersLabel}</pre>
                    </Typography>
                  )}
                </div>
              );
            }
            return null;
          },
          Option: (props: OptionProps) => {
            const { data: option, isMulti, isSelected, value } = props;
            return (
              <div data-cy={value === undefined ? null : `fdd-value-${value}`}>
                {isMulti && (
                  <Checkbox
                    ariaLabel={option.label}
                    checked={isSelected}
                    className={bem(baseClass, "option-checkbox")}
                    onChange={() => {
                      if (isSelected) {
                        onOptionDeselect(option);
                      } else {
                        onOptionSelect(option);
                      }
                    }}
                  />
                )}
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <components.Option {...props} isDisabled={option.disabled} />
              </div>
            );
          }
        }}
        filterOption={filter}
        // $FlowFixMe - the type of formatGroupLabel is incorrect in react-select
        formatGroupLabel={groupFormatFn}
        // One of the properties is of type Option[]|Option|null|void, but since
        // multi select is disabled and an option is always selected, it will
        // only be Option|null. Flow can't figure this out even with an
        // Array.isArray check, so turn it off
        // $FlowFixMe
        formatOptionLabel={formatOptionLabel}
        isOptionDisabled={isOptionDisabled}
        hideSelectedOptions={false}
        inputId={id}
        isClearable={clearable}
        isDisabled={disabled}
        isMulti={multi}
        isSearchable={searchable}
        inputValue={searchValue}
        isLoading={loading}
        menuPlacement={menuPlacement}
        menuPortalTarget={
          menuPortalTarget ? menuPortalTarget.current : undefined
        }
        // $FlowFixMe - the type of noOptionsMessage is incorrect in react-select
        noOptionsMessage={noOptionsMessage}
        onBlur={onBlur}
        onChange={onChange}
        onInputChange={onInputChange}
        onMenuClose={() => {
          onSearchValueChange("");
          selectRef.current.blur();
          setMenuOpen(false);
        }}
        onMenuOpen={() => setMenuOpen(true)}
        openMenuOnFocus
        options={fullOptionList}
        placeholder={
          <>
            {hero && label && (
              <Typography
                color={labelColor}
                className="c-fancy-dropdown__inner-label"
              >
                <div>{label}</div>
              </Typography>
            )}
            {typeof placeholder === "string" ? (
              <Typography
                color={hero && label ? "primary" : "muted"}
                bold={hero}
                size="primary"
              >
                {placeholder}
              </Typography>
            ) : (
              placeholder
            )}
          </>
        }
        ref={selectRef}
        tabSelectsValue={false}
        value={selectedOption}
        menuPosition={menuPosition}
      />
      {searchable && menuOpen && <SearchIcon role="presentation" />}
      {!!error.trim() && (
        <div
          aria-live="polite"
          data-cy={cypress === null ? null : `${cypress}-error`}
        >
          <Error error={error} />
        </div>
      )}
      {helpText && !error && (
        <Typography
          size="secondary"
          data-cy={cypress === null ? null : `${cypress}-help-text`}
        >
          <div className={bem(baseClass, "help-text")}>{helpText}</div>
        </Typography>
      )}
    </div>
  );
  if (label && !hero) {
    return (
      <div>
        <Typography size="secondary" bold color={labelColor}>
          <div className={`${baseClass}__labeltext`}>{label}</div>
        </Typography>
        {component}
      </div>
    );
  }
  return component;
};

const FancyDropdown = ({
  allOption = null,
  ariaLabel = undefined,
  ariaLabelledBy = undefined,
  className = "",
  clearable = false,
  cypress = null,
  disabled = false,
  error = "",
  helpText = "",
  id = undefined,
  loading = false,
  menuPlacement = "auto",
  multi = false,
  noOptionsMessage = () => (
    <Typography color="light" size="primary">
      No results
    </Typography>
  ),
  onOptionDeselect = () => {},
  onOptionSelect,
  options,
  placeholder = "",
  searchable = false,
  selectedOption,
  showSelectedAtTopOfMenu = false,
  warning = false,
  optionFormatFactory = makeFormatOptionLabelFn,
  hideDropDownIndicator = false,
  menuPortalTarget = null,
  label = null,
  labelColor = "light",
  hideErrorCross = false,
  hero = false,
  menuPosition = "absolute"
}: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const searchValueRegExp = useMemo<RegExp | null>(() => {
    return searchValue && searchable
      ? new RegExp(escapeRegExp(searchValue), "gi")
      : null;
  }, [searchValue, searchable]);
  const searchValueRegExpFactory = () => searchValueRegExp;
  return (
    <FancyDropdownUI
      onBlur={() => {
        setSearchValue("");
      }}
      allOption={allOption}
      ariaLabel={ariaLabel}
      ariaLabelledBy={ariaLabelledBy}
      className={className}
      clearable={clearable}
      cypress={cypress}
      disabled={disabled}
      error={error}
      helpText={helpText}
      id={id}
      loading={loading}
      menuPlacement={menuPlacement}
      multi={multi}
      noOptionsMessage={noOptionsMessage}
      onOptionDeselect={onOptionDeselect}
      onOptionSelect={onOptionSelect}
      onSearchValueChange={setSearchValue}
      options={options}
      placeholder={placeholder}
      searchable={searchable}
      searchValue={searchValue}
      searchValueRegExpFactory={searchValueRegExpFactory}
      selectedOption={selectedOption}
      showSelectedAtTopOfMenu={showSelectedAtTopOfMenu}
      warning={warning}
      optionFormatFactory={optionFormatFactory}
      menuPortalTarget={menuPortalTarget}
      hideDropDownIndicator={hideDropDownIndicator}
      label={label}
      labelColor={labelColor}
      hideErrorCross={hideErrorCross}
      hero={hero}
      menuPosition={menuPosition}
    />
  );
};

export default FancyDropdown;
