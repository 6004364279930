// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import Clear from "../../../svg/cross-small.svg";
import "./clearIcon.scss";

type Props = {
  modifier?: string;
  className?: string;
};
const ClearIcon = ({ modifier = "", className = "" }: Props) => (
  <Clear
    className={`${bem("c-clear-icon", null, {
      modifier
    })} ${className}`}
  />
);

export default ClearIcon;
