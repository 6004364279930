// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import Tick from "../../svg/tick.svg";
import Minus from "../../svg/minus.svg";
import LoadingWheel from "../LoadingWheel";
import "./checkbox.scss";

type Props = {
  /**
   * If you're not wrapping the component in a <label> consider setting an ARIA
   * label for good karma
   */
  ariaLabel?: string | null;
  checked?: boolean;
  className?: string;
  cypress?: string | null;
  name?: string | null;
  value?: string;
  onChange: (a: boolean) => void;
  loading?: boolean;
  type?: "primary" | "secondary" | "tertiary";
  id?: string | null;
  minus?: boolean;
  dark?: boolean;
  size?: "large";
  disabled?: boolean;
};

const Checkbox = ({
  ariaLabel = null,
  checked = false,
  className = "",
  cypress = null,
  name = null,
  value,
  onChange,
  loading = false,
  type = "primary",
  id = null,
  minus = false,
  dark,
  size = null,
  disabled = false
}: Props) => {
  const baseClass = "c-checkbox";
  const checkedClass = bem(baseClass, null, {
    checked,
    size
  });
  const disabledClass = disabled ? bem(baseClass, "disabled") : "";
  const onChangeFn = (e) => {
    e.stopPropagation();
    return onChange(!checked);
  };

  // the tick-container div is needed to stop the button moving in FF/Safari.
  // Something about the SVG inside the button causes the button to slide
  // vertically is those browsers
  return (
    <>
      <input
        aria-hidden="true"
        className={bem(baseClass, "input")}
        checked={checked}
        name={name}
        value={value}
        onChange={onChangeFn}
        type="checkbox"
        disabled={loading || disabled}
        id={id}
      />
      {loading ? (
        <div className={baseClass}>
          <LoadingWheel />
        </div>
      ) : (
        <button
          aria-checked={checked.toString()}
          aria-label={ariaLabel}
          className={`${checkedClass} ${className} ${disabledClass}`}
          data-cy={cypress}
          onClick={onChangeFn}
          role="checkbox"
          type="button"
          disabled={disabled}
        >
          <div
            className={bem(baseClass, "tick-container", {
              checked,
              type,
              dark,
              size
            })}
          >
            {minus ? (
              <Minus
                className={bem(baseClass, "tick-icon", {
                  checked
                })}
                role="presentation"
              />
            ) : (
              <Tick
                className={bem(baseClass, "tick-icon", {
                  checked
                })}
                role="presentation"
              />
            )}
          </div>
        </button>
      )}
    </>
  );
};

export default Checkbox;
