// @ts-nocheck
import { createFilter } from "react-select";
import type { Option } from "./option";

export type Filter = (
  a: {
    data: Option;
    label: string;
    value: any;
  },
  searchValue: string
) => boolean;
export type FilterFactory = (allOption: Option | null) => Filter;
export { createFilter as libraryFilter };
const makeDefaultFilter: FilterFactory = (allOption) => {
  const libraryFilter = createFilter({
    ignoreCase: true,
    ignoreAccents: false,
    trim: true,
    matchFrom: "any"
  });
  return (optionData, inputValue) => {
    const { data: option } = optionData;
    const isTheAllOption = allOption && option === allOption;
    if (isTheAllOption) {
      // only show the All option when nothing has been typed
      return !inputValue;
    }
    return libraryFilter(optionData, inputValue);
  };
};
export default makeDefaultFilter;
