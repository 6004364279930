// @ts-nocheck
import React, { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import bem from "@justpark/helpers/src/bem/bem";
import type { Element, Node, Ref } from "react";
import type { OptionType } from "react-select";
import Typography from "../Typography";

export type Option = OptionType & {
  label: string;
  value: any; // must be unique
};
export type OptionGroup = {
  label: string;
  // eslint-disable-next-line react/no-unused-prop-types
  options: Option[];
};
export type OptionsArray = Array<{
  label: string;
  options?: Option[];
  value?: any;
}>; // Array<Option | OptionGroup>

export type FormatOptionLabelMeta = {
  context: "menu" | "value";
  // menu is for the dropdown, value shows in the input
  inputValue: string;
  // the current search term
  selectValue: Option | undefined | null; // the selected option
};
export type OptionFormatFn = (
  option: Option,
  meta: FormatOptionLabelMeta
) => Node | string;
export type OptionFormatFactory = (
  searchValueRegExp?: RegExp | null
) => OptionFormatFn;

// react-select only types for string, but it takes Nodes, too!
export type OptionGroupFormatFn = (group: OptionGroup) => Node | string | null;
type DropdownItemProps = {
  className: string;
  option: Option;
  searchValueRegExp: RegExp | null;
  innerLabel?: string;
  labelColor?: string;
};
const baseClass = "c-fancy-dropdown";
function highlightMatches(
  haystack: string,
  needle: RegExp,
  wrapper: (match: RegExp$matchResult, partNumber: number) => Node
): Node[] {
  let matchResult: RegExp$matchResult | null;
  const labelParts: Node[] = [];
  let lastIndex = 0;
  // this is a common pattern for matching a RegExp repeatedly until no more matches are found
  // eslint-disable-next-line no-cond-assign
  while ((matchResult = needle.exec(haystack))) {
    if (lastIndex !== matchResult.index) {
      // add the text from the end of the last match until this match
      labelParts.push(haystack.substring(lastIndex, matchResult.index));
    }
    // add the matching part wrapped in bold
    const wrappedMatch = wrapper(matchResult, labelParts.length);
    labelParts.push(wrappedMatch);
    lastIndex = needle.lastIndex;
  }
  if (haystack.length > lastIndex) {
    // there is more text after the final match, so add that remaining text
    labelParts.push(haystack.substring(lastIndex));
  }
  return labelParts;
}

/** Handles rendering of dropdown items (and selected items). Encapsulates
 *  highlighting of search term matches.
 */
const DropdownItem = ({
  className,
  option,
  searchValueRegExp,
  innerLabel,
  labelColor
}: DropdownItemProps) => {
  const { label } = option;
  const itemClassName = `${className} ${bem(baseClass, "option", {
    "search-result": !!searchValueRegExp
  })}`;
  const innerLabelClassName = bem(baseClass, "inner-label");
  if (searchValueRegExp) {
    const labelParts = highlightMatches(
      label,
      searchValueRegExp,
      (match: RegExp$matchResult, partCounter) => (
        <Typography
          bold
          className={bem(baseClass, "option-match")}
          color="primary"
          key={`part-${partCounter}`}
          size="primary"
        >
          {match[0]}
        </Typography>
      )
    );
    return (
      <Typography className={itemClassName} color="primary" size="primary">
        <div>{labelParts}</div>
      </Typography>
    );
  }
  return (
    <Typography className={itemClassName} color="primary" size="primary">
      {innerLabel && (
        <Typography color={labelColor} className={innerLabelClassName}>
          <div>{innerLabel}</div>
        </Typography>
      )}
      <div className={bem(baseClass, "selected-item-value")}>{label}</div>
    </Typography>
  );
};

/**
 * Wrap the All option as a group of its own to give it padding and the underline
 */
function makeAllOption(option: Option): OptionGroup {
  return {
    label: "",
    options: [option]
  };
}
export function addAllOptionToOptions(
  options: OptionsArray,
  allOption?: Option | null
): OptionsArray {
  if (allOption) {
    return [makeAllOption(allOption), ...options];
  }
  return options;
}
export function formatGroupLabel({ label }: OptionGroup): Node | null {
  return label ? (
    <Typography bold color="muted" size="subtext">
      {label}
    </Typography>
  ) : null;
}

/**
 * The default option format factory; it will add highlighting to text matches
 */
export const makeFormatOptionLabelFn: OptionFormatFactory = (
  searchValueRegExp?: RegExp | null,
  innerLabel?: string,
  labelColor?: string
) => {
  return function formatOptionLabel(
    option: Option,
    { context }: FormatOptionLabelMeta
  ): Element<typeof DropdownItem> {
    if (context === "menu") {
      // this option is in the open menu
      return (
        <DropdownItem
          className={bem(baseClass, "option", {
            "menu-option": true
          })}
          option={option}
          searchValueRegExp={searchValueRegExp || null}
        />
      );
    }
    // this is the select option visible when the menu is closed
    return (
      <DropdownItem
        className={bem(baseClass, "option", {
          "selected-item": true
        })}
        option={option}
        searchValueRegExp={null}
        innerLabel={innerLabel}
        labelColor={labelColor}
      />
    );
  };
};
function makeSelectedOptionsGroup(
  selectedOptions: Option | Option[] | null,
  allOption: Option | null
): OptionGroup | null {
  if (selectedOptions === null) {
    return selectedOptions;
  }
  const selectedOptionsAsArray = Array.isArray(selectedOptions)
    ? selectedOptions
    : [selectedOptions];
  const options = allOption
    ? selectedOptionsAsArray.filter((opt) => opt.value !== allOption.value)
    : selectedOptionsAsArray;
  return {
    label: "",
    options
  };
}
export function moveSelectedOptionsToTop(
  options: OptionsArray,
  selectedOptions: Option | Option[] | null,
  allOption: Option | null
): OptionsArray {
  const selectedOptionGroup = makeSelectedOptionsGroup(
    selectedOptions,
    allOption
  );
  return selectedOptionGroup ? [selectedOptionGroup, ...options] : options;
}
function optionsChanged(
  options1: Option | Option[] | null,
  options2: Option | Option[] | null
): boolean {
  if (options1 === options2) {
    return false;
  }
  if (options1 === null || options2 === null) {
    return true;
  }
  return options1.length !== options2.length;
}
export function useScrollOnMultiOptionSelect(
  isMulti: boolean,
  menuOpen: boolean,
  showSelectedAtTopOfMenu: boolean,
  selectRef: Ref<any>,
  selectedOptions: Option | Option[] | null
) {
  const previousMenuHeightRef = useRef(0);
  const previousSelectedOptionsRef = useRef(selectedOptions);

  // get initial list height when the menu opens and scroll to top
  useLayoutEffect(() => {
    if (
      menuOpen &&
      isMulti &&
      showSelectedAtTopOfMenu &&
      selectRef.current?.menuListRef
    ) {
      previousMenuHeightRef.current =
        selectRef.current.menuListRef.scrollHeight;
      // eslint-disable-next-line no-param-reassign
      selectRef.current.menuListRef.scrollTop = 0;
    }
  }, [isMulti, menuOpen, selectRef, showSelectedAtTopOfMenu]);
  const scrollTopBeforeOptionChange = useMemo(() => {
    if (
      isMulti &&
      menuOpen &&
      showSelectedAtTopOfMenu &&
      optionsChanged(selectedOptions, previousSelectedOptionsRef.current)
    ) {
      return selectRef.current?.menuListRef?.scrollTop || 0;
    }
    return 0;
  }, [isMulti, menuOpen, selectRef, selectedOptions, showSelectedAtTopOfMenu]);

  // adjust scrollTop by height change
  useLayoutEffect(() => {
    if (
      isMulti &&
      menuOpen &&
      showSelectedAtTopOfMenu &&
      selectRef.current?.menuListRef &&
      optionsChanged(selectedOptions, previousSelectedOptionsRef.current)
    ) {
      const heightChange =
        selectRef.current.menuListRef.scrollHeight -
        previousMenuHeightRef.current;
      if (heightChange) {
        // eslint-disable-next-line no-param-reassign
        selectRef.current.menuListRef.scrollTop =
          scrollTopBeforeOptionChange + heightChange;
      }
      previousMenuHeightRef.current =
        selectRef.current.menuListRef.scrollHeight;
    }
  }, [
    isMulti,
    menuOpen,
    scrollTopBeforeOptionChange,
    selectRef,
    selectedOptions,
    showSelectedAtTopOfMenu
  ]);
  useEffect(() => {
    previousSelectedOptionsRef.current = selectedOptions;
  }, [selectedOptions]);
}
export const isOptionDisabled = (option) => {
  return option.disabled;
};
