// @ts-nocheck
const specialCharRegExp = /[.*+?^${}()|[\]\\]/g;

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
/**
 * Escape a string ready for use in a regular expression.
 */
export default function escapeRegExp(val: string): string {
  return val && val.replace(specialCharRegExp, "\\$&");
}
